<template>
  <div class="mb-8">
    <section class="text-gray-700">
      <main class="container mx-auto px-4 md:w-2/3">
        <section class="">
          <!---->
          <div class="global-status flex justify-center partial-outage">
            <span>Partial Outage</span>
            <span class="global-status-icon">
              <svg
                version="1.1"
                viewBox="0 0 512 512"
                class="svg-inline--fa fa-w-16 svg-icon svg-fill"
              >
                <path
                  pid="0"
                  fill="#fff"
                  d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                />
              </svg>
            </span>
          </div>
          <!---->
          <div>
            <div class="systems-container flex flex-col">
              <div class="system flex flex-row justify-between operational">
                <div class="system-title">
                  Covid19 Dashboard

                  <!---->
                </div>
                <div class="system-status">
                  <span class="hidden sm:inline">Operational </span>
                  <svg
                    version="1.1"
                    viewBox="0 0 512 512"
                    class="svg-inline--fa fa-w-16 svg-icon svg-fill"
                  >
                    <path
                      pid="0"
                      fill="#28a745"
                      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                    />
                  </svg>
                </div>
              </div>
              <div class="system flex flex-row justify-between operational">
                <div class="system-title">
                  Services

                  <!---->
                </div>
                <div class="system-status">
                  <span class="hidden sm:inline">Operational </span>
                  <svg
                    version="1.1"
                    viewBox="0 0 512 512"
                    class="svg-inline--fa fa-w-16 svg-icon svg-fill"
                  >
                    <path
                      pid="0"
                      fill="#28a745"
                      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="
                  system
                  flex flex-row
                  justify-between
                  degraded-performance
                "
              >
                <div class="system-title">
                  Blogs

                  <!---->
                </div>
                <div class="system-status">
                  <span class="hidden sm:inline">Degraded Performance </span>
                  <svg
                    version="1.1"
                    viewBox="0 0 512 512"
                    class="svg-inline--fa fa-w-16 svg-icon svg-fill"
                  >
                    <path
                      pid="0"
                      fill="#6f42c1"
                      d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="
                  system
                  flex flex-row
                  justify-between
                  degraded-performance
                "
              >
                <div class="system-title">
                  Enterprise Services

                  <!---->
                </div>
                <div class="system-status">
                  <span class="hidden sm:inline">Degraded Performance </span>
                  <svg
                    version="1.1"
                    viewBox="0 0 512 512"
                    class="svg-inline--fa fa-w-16 svg-icon svg-fill"
                  >
                    <path
                      pid="0"
                      fill="#6f42c1"
                      d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    />
                  </svg>
                </div>
              </div>
              <div class="system flex flex-row justify-between major-outage">
                <div class="system-title">
                  User Account

                  <!---->
                </div>
                <div class="system-status">
                  <span class="hidden sm:inline">Outage </span>
                  <svg
                    version="1.1"
                    viewBox="0 0 512 512"
                    class="svg-inline--fa fa-w-16 svg-icon svg-fill"
                  >
                    <path
                      pid="0"
                      fill="#d73a49"
                      d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="timeline-container">
            <h2>Latest Incidents</h2>
            <ul class="timeline">
              <!---->
              <li class="timeline-incident major-outage">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-19T00:00:00.000Z">
                    Jul 18, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <!---->
                  <div class="incident major-outage unresolved">
                    <div class="incident-header level-4">
                      <div class="incident-title">
                        <span class="badge"> Unresolved </span>
                        <a href="#!">
                          <h4>Migration</h4>
                        </a>
                      </div>
                      <div class="incident-systems">
                        <span class="badge"> User Account </span>
                      </div>
                    </div>
                    <div class="incident-subtitle">
                      <div class="status">
                        <svg
                          version="1.1"
                          viewBox="0 0 512 512"
                          class="svg-inline--fa fa-w-16 svg-icon svg-fill"
                        >
                          <path
                            pid="0"
                            fill="#d73a49"
                            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                          />
                        </svg>
                        Outage
                      </div>
                      <div>
                        <time datetime="2021-07-19T06:03:46.141Z">
                          Jul 18, 06:03 UTC
                        </time>
                      </div>
                    </div>
                    <div class="incident-body">
                      <div class="markdown">
                        <p>
                          Due to migration our website to new system and new
                          language our few services down graded.
                        </p>
                        <!--- language code: en -->
                      </div>
                    </div>
                    <div class="hidden">
                      <svg
                        version="1.1"
                        viewBox="0 0 576 512"
                        class="svg-inline--fa fa-w-12 ml-1 svg-icon svg-fill"
                      >
                        <path
                          pid="0"
                          fill="d73a49"
                          d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-incident degraded-performance">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-19T00:00:00.000Z">
                    Jul 18, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <!---->
                  <div class="incident degraded-performance unresolved">
                    <div class="incident-header level-4">
                      <div class="incident-title">
                        <span class="badge"> Unresolved </span>
                        <a href="#!">
                          <h4>Migration</h4>
                        </a>
                      </div>
                      <div class="incident-systems">
                        <span class="badge"> Blogs </span>
                        <span class="badge"> Enterprise Services </span>
                      </div>
                    </div>
                    <div class="incident-subtitle">
                      <div class="status">
                        <svg
                          version="1.1"
                          viewBox="0 0 512 512"
                          class="svg-inline--fa fa-w-16 svg-icon svg-fill"
                        >
                          <path
                            pid="0"
                            fill="#6f42c1"
                            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                          />
                        </svg>
                        Degraded Performance
                      </div>
                      <div>
                        <time datetime="2021-07-19T06:03:46.141Z">
                          Jul 18, 06:03 UTC
                        </time>
                      </div>
                    </div>
                    <div class="incident-body">
                      <div class="markdown">
                        <p>
                          Due to migration our website to new system and new
                          language our few services down graded.
                        </p>
                        <!--- language code: en -->
                      </div>
                    </div>
                    <div class="hidden">
                      <svg
                        version="1.1"
                        viewBox="0 0 576 512"
                        class="svg-inline--fa fa-w-12 ml-1 svg-icon svg-fill"
                      >
                        <path
                          pid="0"
                          fill="6f42c1"
                          d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </li>

              <li class="timeline-incident operational">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-17T00:00:00.000Z">
                    Jul 17, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <div class="message">No incidents reported.</div>
                </div>
              </li>
              <li class="timeline-incident operational">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-16T00:00:00.000Z">
                    Jul 16, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <div class="message">No incidents reported.</div>
                </div>
              </li>
              <li class="timeline-incident operational">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-15T00:00:00.000Z">
                    Jul 15, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <div class="message">No incidents reported.</div>
                </div>
              </li>
              <li class="timeline-incident operational">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-14T00:00:00.000Z">
                    Jul 14, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <div class="message">No incidents reported.</div>
                </div>
              </li>
              <li class="timeline-incident operational">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-13T00:00:00.000Z">
                    Jul 13, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <div class="message">No incidents reported.</div>
                </div>
              </li>
              <li class="timeline-incident operational">
                <h3 class="timeline-incident-title">
                  <time datetime="2021-07-12T00:00:00.000Z">
                    Jul 12, 2021
                  </time>
                </h3>
                <div class="timeline-incident-body">
                  <div class="message">No incidents reported.</div>
                </div>
              </li>
            </ul>
            <div class="text-center">
              <button disabled="true" class="btn mb-4">
                Incidents History
              </button>
            </div>
          </div>
        </section>
      </main>
      <p class="text-center text-base mt-2 mb-6">
        If you discover any error|bug do not forget to report in our
        <router-link
          class="text-indigo-600 no-underline hover:underline"
          to="/contact"
          >Contact</router-link
        >
        Section.
      </p>
    </section>
  </div>
</template>

<style scoped>
:root {
  --transparent: transparent;
  --black: #1b1f23;
  --grey-darkest: #586069;
  --grey-darker: #6a737d;
  --grey-dark: #959da5;
  --grey: #d1d5da;
  --grey-light: #e1e4e8;
  --grey-lighter: #f6f8fa;
  --grey-lightest: #fafbfc;
  --white: #fff;
  --blue: #0366d6;
  --purple: #6f42c1;
  --green: #28a745;
  --orange: #f66a0a;
  --red: #d73a49;
  --yellow: #ffd33d;
  --font-family-sans-serif: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, -apple-system,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  --font-family-monospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
    monospace;
}

a:hover {
  color: #1b1f23;
  color: var(--black);
}

.btn {
  display: inline-block;
  border-radius: 0.25rem;
  font-weight: 500;
  border-width: 1px;
  padding: 0.5rem 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  color: #586069;
  background-color: #fff;
  border-color: #e1e4e8;
}

.btn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  color: #1b1f23;
  border-color: #d1d5da;
}

.badge {
  border-radius: 9999px;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
}

.badge,
.popover {
  font-size: 0.875rem;
}

.popover {
  position: absolute;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin: 0.25rem;
  max-width: 20rem;
  text-align: center;
  color: #fff;
  background-color: #586069;
}

.scheduled-container,
.timeline-container {
  margin-top: 1.5rem;
}

.scheduled-container > h2,
.timeline-container > h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.timeline {
  list-style: none;
  margin: 0;
  padding: 0;
}

.timeline-incident {
  border-left-width: 2px;
  margin-left: 0.5rem;
  position: relative;
  padding-top: 0;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  border-color: #e1e4e8;
}

.timeline-incident:last-child {
  border-color: transparent;
}

.timeline-incident:before {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 2px;
  position: absolute;
  border-radius: 100%;
  z-index: 10;
  background-color: #fff;
  content: " ";
  left: -13px;
  top: 0;
}

.timeline-incident:before,
.under-maintenance.timeline-incident:before {
  border-color: #6a737d;
}

.degraded-performance.timeline-incident:before {
  border-color: #6f42c1;
}

.partial-outage.timeline-incident:before {
  border-color: #f66a0a;
}

.major-outage.timeline-incident:before {
  border-color: #d73a49;
}

.operational.timeline-incident:before {
  border-color: #e1e4e8;
}

.timeline-incident-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.timeline-incident-body {
  padding-bottom: 0.5rem;
}

.timeline-incident-body .message {
  color: #6a737d;
}

.incident {
  background-color: #fff;
  border-color: #e1e4e8;
  line-height: 1.5;
  padding: 1.5rem 1rem 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-width: 1px;
}

.incident .incident-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .incident .incident-header {
    flex-direction: row;
  }
}

.incident .incident-header.level-0 .incident-title {
  display: block;
}

.incident .incident-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .incident .incident-title {
    align-items: center;
    flex-direction: row;
  }
}

.incident .incident-title.level-0 {
  text-align: center;
}

.incident .incident-title .badge {
  display: block;
  text-align: center;
  color: #fff;
}

@media (min-width: 576px) {
  .incident .incident-title .badge {
    margin-right: 0.5rem;
  }
}

.incident .incident-title a {
  color: #1b1f23;
}

.incident .incident-title a * {
  font-size: 1.125rem;
  display: block;
}

.incident .incident-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .incident .incident-subtitle {
    flex-direction: row;
  }
}

.incident .incident-subtitle time {
  font-weight: 500;
}

.incident .incident-subtitle .status {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .incident .incident-subtitle .status {
    margin-bottom: 0;
  }
}

.incident .incident-systems {
  margin-bottom: 0.5rem;
  text-align: center;
}

.incident .incident-systems .badge {
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  color: #6a737d;
  background-color: #f6f8fa;
}

.incident.under-maintenance.unresolved .incident-title .badge {
  background-color: #6a737d;
}

.incident.under-maintenance .incident-subtitle .status,
.incident.under-maintenance.unresolved .incident-title a {
  color: #6a737d;
}

.incident.degraded-performance.unresolved .incident-title .badge {
  background-color: #6f42c1;
}

.incident.degraded-performance .incident-subtitle .status,
.incident.degraded-performance.unresolved .incident-title a {
  color: #6f42c1;
}

.incident.partial-outage.unresolved .incident-title .badge {
  background-color: #f66a0a;
}

.incident.partial-outage .incident-subtitle .status,
.incident.partial-outage.unresolved .incident-title a {
  color: #f66a0a;
}

.incident.major-outage.unresolved .incident-title .badge {
  background-color: #d73a49;
}

.incident.major-outage .incident-subtitle .status,
.incident.major-outage.unresolved .incident-title a {
  color: #d73a49;
}

.incident.resolved .incident-title .badge {
  background-color: #28a745;
}

.incident.scheduled .incident-title {
  display: block;
  text-align: center;
}

.incident-body > div,
.incident-body > p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.incident-body .markdown {
  margin-bottom: 0;
}

.incident-body a {
  color: #0366d6;
}

.incident-body a.external svg {
  color: #959da5;
}

.incident-body .update-block {
  display: flex;
  flex-direction: column-reverse;
  border-bottom-width: 1px;
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.incident-body .update-block:first-child {
  padding-top: 0;
}

.incident-body .update-block:first-child .update-block-content {
  margin-top: 0;
}

.incident-body .update-block:last-child {
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.incident-body .update-block:last-child .update-block-date {
  margin-bottom: 0;
}

.incident-body .update-block-date {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #959da5;
}

.incident-body .update-block-content {
  flex: 0 1 auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.incident-body .update-block-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
}

.systems-container {
  padding: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-width: 1px;
  background-color: #fff;
  border-color: #e1e4e8;
}

.system {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  background-color: #f6f8fa;
}

.system .system-title {
  color: #6a737d;
}

.system .system-title-info {
  cursor: pointer;
  color: #959da5;
}

.system .system-status {
  text-align: right;
}

.system .system-status,
.system.under-maintenance .system-status {
  color: #6a737d;
}

.system.degraded-performance .system-status {
  color: #6f42c1;
}

.system.partial-outage .system-status {
  color: #f66a0a;
}

.system.major-outage .system-status {
  color: #d73a49;
}

.system.operational .system-status {
  color: #28a745;
}

.global-status {
  align-content: center;
  padding: 1rem 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  background-color: #f6f8fa;
  color: #fff;
}

.global-status .global-status-icon {
  margin-left: 0.5rem;
}

.global-status.under-maintenance {
  background-color: #6a737d;
}

.global-status.degraded-performance {
  background-color: #6f42c1;
}

.global-status.partial-outage {
  background-color: #f66a0a;
}

.global-status.major-outage {
  background-color: #d73a49;
}

.global-status.operational {
  background-color: #28a745;
}

.svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  vertical-align: -0.125em;
}

.svg-inline--fa.fa-lg {
  vertical-align: -0.225em;
}

.svg-inline--fa.fa-w-1 {
  width: 0.0625em;
}

.svg-inline--fa.fa-w-2 {
  width: 0.125em;
}

.svg-inline--fa.fa-w-3 {
  width: 0.1875em;
}

.svg-inline--fa.fa-w-4 {
  width: 0.25em;
}

.svg-inline--fa.fa-w-5 {
  width: 0.3125em;
}

.svg-inline--fa.fa-w-6 {
  width: 0.375em;
}

.svg-inline--fa.fa-w-7 {
  width: 0.4375em;
}

.svg-inline--fa.fa-w-8 {
  width: 0.5em;
}

.svg-inline--fa.fa-w-9 {
  width: 0.5625em;
}

.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}

.svg-inline--fa.fa-w-11 {
  width: 0.6875em;
}

.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

.svg-inline--fa.fa-w-13 {
  width: 0.8125em;
}

.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}

.svg-inline--fa.fa-w-15 {
  width: 0.9375em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa.fa-w-17 {
  width: 1.0625em;
}

.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

.svg-inline--fa.fa-w-19 {
  width: 1.1875em;
}

.svg-inline--fa.fa-w-20 {
  width: 1.25em;
}

.svg-inline--fa.fa-pull-left {
  margin-right: 0.3em;
  width: auto;
}

.svg-inline--fa.fa-pull-right {
  margin-left: 0.3em;
  width: auto;
}

.svg-inline--fa.fa-border {
  height: 1.5em;
}

.svg-inline--fa.fa-li {
  width: 2em;
}

.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center center;
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1em;
}

.fa-layers-counter,
.fa-layers-text {
  display: inline-block;
  position: absolute;
  text-align: center;
}

.fa-layers-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
}

.fa-layers-counter {
  background-color: #ff253a;
  border-radius: 1em;
  box-sizing: border-box;
  color: #fff;
  height: 1.5em;
  line-height: 1;
  max-width: 5em;
  min-width: 1.5em;
  overflow: hidden;
  padding: 0.25em;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  transform: scale(0.25);
  transform-origin: top right;
}

.fa-layers-bottom-right {
  bottom: 0;
  right: 0;
  top: auto;
  transform: scale(0.25);
  transform-origin: bottom right;
}

.fa-layers-bottom-left {
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
  transform: scale(0.25);
  transform-origin: bottom left;
}

.fa-layers-top-right {
  right: 0;
  top: 0;
  transform: scale(0.25);
  transform-origin: top right;
}

.fa-layers-top-left {
  left: 0;
  right: auto;
  top: 0;
  transform: scale(0.25);
  transform-origin: top left;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: 0.08em solid #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s steps(8) infinite;
  animation: fa-spin 1s steps(8) infinite;
}

@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-both,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-rotate-90 {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  position: relative;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em;
}

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em;
}

.fa-inverse {
  color: #fff;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.svg-inline--fa .fa-primary {
  fill: currentColor;
  opacity: 1;
}

.svg-inline--fa .fa-secondary {
  fill: currentColor;
}

.svg-inline--fa .fa-secondary,
.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: 0.4;
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: 1;
}

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: #000;
}

.fad.fa-inverse {
  color: #fff;
}

.nuxt-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 0;
  opacity: 1;
  transition: width 0.1s, opacity 0.4s;
  background-color: #1b1f23;
  z-index: 999999;
}
</style>
